<template>
  <ClientOnly>
    <div>
      <div v-if="session.isLoggedIn && userContext.initialized">
        <slot />
      </div>
      <div
        v-else
        class="flex flex-col items-center justify-center p-md"
        :class="classes"
      >
        <div
          class="flex flex-col items-center justify-center w-full gap-sm font-deco-alt text-alt-lg"
          :class="{ '!w-full': fullWidth }"
        >
          <ButtonBasic
            :class="{ '!w-full': fullWidth }"
            :full-width="fullWidth"
            :label="t('user.loginStatus.login')"
            :btn-style="EButtonStyle.CTA"
            bg-color="var(--thm-btn-primary-base)"
            :testid="testid"
            @click="
              () => {
                dialogStore.openDialog(DialogIdent.LOGIN);
                $emit('click');
              }
            "
          />
          <div
            v-if="!hideRegister"
            class="flex flex-col items-center justify-center"
          >
            <div
              class="flex gap-2 cursor-pointer place-items-center text-primary-base font-deco-alt text-alt-lg"
              @click="
                () => {
                  dialogStore.openDialog(DialogIdent.REGISTRATION);
                  $emit('click');
                }
              "
            >
              <span class="anim__underline-link anim-link">
                {{ t('user.loginStatus.register') }}
              </span>
              <FaIcon icon-class="fas fa-chevron-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ClientOnly>
</template>
<script setup lang="ts">
import { EButtonStyle } from '~~/src/@types/basic-button';
import { BasicButton as ButtonBasic, Icon as FaIcon } from '~/complib';
import { DialogIdent, useDialogStore } from '~/stores/useDialogStore';
import { useSessionStore } from '~/stores/useSessionStore';
import { useUserContext } from '~/stores/useUserContext';

defineProps({
  hideRegister: {
    type: Boolean,
    required: false,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    required: false,
    default: false,
  },
  classes: {
    type: String,
    required: false,
    default: () => '',
  },
  testid: {
    type: String,
    required: false,
    default: '',
  },
});

defineEmits<{
  (e: 'click'): void;
}>();

const session = useSessionStore();
const userContext = useUserContext();
const dialogStore = useDialogStore();

const { t } = useTrans();
</script>
